<template>
  <div class="layout">
    <div class="banner">
      <h2 class="h2">{{$t('StarMerchant')}}</h2>
    </div>
    <div class="filter cl">
      <div class="fr">
        <el-select class="select" filterable clearable size="small" :placeholder="`${$t('Country')}/${$t('Region')}`"
                   v-model="areas">
          <el-option v-for="item of countryOptions"
                     :key="item.id"
                     :label="item.name"
                     :value="item.code">
          </el-option>
        </el-select>
        <el-select class="select" filterable clearable size="small" :placeholder="$t('City')" v-model="city">
          <el-option v-for="item of cityOptions"
                     :key="item.id"
                     :label="item.name"
                     :value="item.code">
          </el-option>
        </el-select>
      </div>
    </div>
    <div class="traveler-list cl">
      <div class="traveler-item" v-for="item of list" :key="item.businessId" @click="openDetail(item.userId)">
        <div class="cover">
          <img class="img" :src="item.photoUrl"/>
        </div>
        <div class="name">{{item.businessName}}</div>
      </div>
    </div>
    <div class="txt-c" v-if="totalPages>page||loading">
      <a class="btn-more" :disabled="loading" href="javascript:;" @click="loadMore">{{loading?'loading……':$t('View more')}}</a>
    </div>
  </div>
</template>
<script>
  import {getBusinessList} from '../../../api/admin-business';
  import {getDictList} from '../../../api/common';
  import {getCityList} from '../../../api/admin-common';

  export default {
    name: 'AdminBusinessList',
    data() {
      return {
        page: 1,
        list: [],
        totalPages: 0,
        loading: false,
        areas: '',
        countryOptions: [],
        cityOptions: [],
        city: ''
      };
    },
    created() {
      this.getList();
      this.getDict();
    },
    methods: {
      openDetail(id) {
        const routeUrl = this.$router.resolve({name: 'AdminBusinessDetail', params: {id}});
        window.open(routeUrl.href, '_blank');
      },
      // 获取字典数据
      getDict() {
        getDictList('country').then(res => {
          this.countryOptions = res.value;
        });
      },
      // 获取商户列表
      getList() {
        if (!this.loading) {
          const data = {page: this.page, perPage: 18};
          this.loading = true;
          getBusinessList(data).then(res => {
            const {value} = res;
            const list = value.list || [];
            this.list = data.page === 1 ? list : this.list.concat(list);
            this.totalPages = value.totalPages;
            this.loading = false;
          });
        }
      },
      loadMore() {
        this.page += 1;
        this.getList();
      }
    },
    watch: {
      areas: function (value) {
        this.cityOptions = [];
        this.city = '';
        // 获取城市字典
        if (value) {
          getCityList(value).then(res => {
            const list = res.value || [];
            this.cityOptions = list;
          });
        }
      }
    }
  };
</script>
<style scoped lang="less">
  .banner{
    position:relative;height:200px;margin-top:30px;text-align:center;background:url(../../../assets/images/banner.jpg) no-repeat top center;
    &:after{position:absolute;top:0;right:0;bottom:0;left:0;background-color:rgba(255, 111, 97, 0.7);content:'';}
    &:before{display:inline-block;height:100%;vertical-align:middle;content:'';}
    .h2{display:inline-block;position:relative;z-index:1;vertical-align:middle;text-align:center;text-shadow:3px 2px 0 #ff6f61;color:#fff;font-size:48px;font-weight:bold;}
  }
  .traveler-list{padding-bottom:60px;}
  .traveler-item{
    float:left;width:120px;height:160px;margin-top:60px;margin-right:48px;cursor:pointer;
    &:hover{opacity:0.8;transition:opacity 0.3s;}
    &:nth-child(6n){margin-right:0;}
    .cover{height:120px;overflow:hidden;border:4px solid rgba(254, 112, 97, 0.4);border-radius:50%;}
    .name{margin-top:20px;overflow:hidden;line-height:20px;white-space:nowrap;text-overflow:ellipsis;text-align:center;}
  }
  .filter{
    margin-top:30px;
    .select{width:120px;margin-left:8px;}
  }
  .btn-more{display:inline-block;margin-bottom:30px;padding:9px 21px;color:#ff6f61;font-size:16px;border:1px solid #ff6f61;border-radius:4px;}
</style>
